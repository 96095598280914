const weatherCategories: {
  [key: number]: "sunny" | "cloudy" | "rainy" | "snowy";
} = {
  // ☀️ SUNNY
  1000: "sunny", // Sunny / Clear

  // ☁️ CLOUDY
  1003: "cloudy", // Partly cloudy
  1006: "cloudy", // Cloudy
  1009: "cloudy", // Overcast
  1030: "cloudy", // Mist
  1135: "cloudy", // Fog
  1147: "cloudy", // Freezing fog

  // 🌧 RAINY
  1063: "rainy", // Patchy rain possible
  1087: "rainy", // Thundery outbreaks possible
  1150: "rainy", // Patchy light drizzle
  1153: "rainy", // Light drizzle
  1168: "rainy", // Freezing drizzle
  1171: "rainy", // Heavy freezing drizzle
  1180: "rainy", // Patchy light rain
  1183: "rainy", // Light rain
  1186: "rainy", // Moderate rain at times
  1189: "rainy", // Moderate rain
  1192: "rainy", // Heavy rain at times
  1195: "rainy", // Heavy rain
  1198: "rainy", // Light freezing rain
  1201: "rainy", // Moderate or heavy freezing rain
  1240: "rainy", // Light rain shower
  1243: "rainy", // Moderate or heavy rain shower
  1246: "rainy", // Torrential rain shower
  1273: "rainy", // Patchy light rain with thunder
  1276: "rainy", // Moderate or heavy rain with thunder

  // ❄️ SNOWY
  1066: "snowy", // Patchy snow possible
  1069: "snowy", // Patchy sleet possible
  1072: "snowy", // Patchy freezing drizzle possible
  1114: "snowy", // Blowing snow
  1117: "snowy", // Blizzard
  1204: "snowy", // Light sleet
  1207: "snowy", // Moderate or heavy sleet
  1210: "snowy", // Patchy light snow
  1213: "snowy", // Light snow
  1216: "snowy", // Patchy moderate snow
  1219: "snowy", // Moderate snow
  1222: "snowy", // Patchy heavy snow
  1225: "snowy", // Heavy snow
  1237: "snowy", // Ice pellets
  1249: "snowy", // Light sleet showers
  1252: "snowy", // Moderate or heavy sleet showers
  1255: "snowy", // Light snow showers
  1258: "snowy", // Moderate or heavy snow showers
  1261: "snowy", // Light showers of ice pellets
  1264: "snowy", // Moderate or heavy showers of ice pellets
  1279: "snowy", // Patchy light snow with thunder
  1282: "snowy", // Moderate or heavy snow with thunder
};

export default weatherCategories;
