// src/routes/TestingShit.tsx
import React, { useEffect, useState, useMemo } from "react";
import styled from "styled-components";
import { about, weatherCategories } from "../db";
import O_WeatherCardsGrid from "../components/Organisms/O_WeatherCardsGrid";

const API_KEY = process.env.REACT_APP_WEATHER_API_KEY;

interface NotFoundPageProps {
  currentLanguage: "en" | "ru";
  t: (key: string) => string;
}

const PageWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TestingShit: React.FC<{
  currentLanguage: "en" | "ru";
  t: (key: string) => string;
}> = ({ t, currentLanguage }) => {
  const [weatherData, setWeatherData] = useState<{
    [city: string]: {
      temp: string;
      condition: "sunny" | "cloudy" | "rainy" | "snowy";
      isNight: boolean;
    };
  }>({});

  const cityNames = useMemo(
    () => about.languages.map((lang) => lang.cityName.en),
    []
  );

  useEffect(() => {
    const fetchWeather = async () => {
      console.time("⏳ Fetching Weather Data"); // ✅ Always start timing

      try {
        const responses = await Promise.all(
          cityNames.map((city) =>
            fetch(
              `https://api.weatherapi.com/v1/current.json?key=${API_KEY}&q=${city}`
            )
              .then((res) => res.json())
              .catch((error) => {
                console.error(`❌ Error fetching weather for ${city}:`, error);
                return null;
              })
          )
        );

        console.timeEnd("⏳ Fetching Weather Data"); // ✅ Always end timing after API response

        const results = responses.reduce((acc, data, index) => {
          if (!data) return acc;

          const city = cityNames[index];
          const weatherCode = data.current.condition.code;
          const mappedCondition = weatherCategories[weatherCode] ?? "cloudy";
          const isNight = data.current.is_day === 0;

          acc[city] = {
            temp: Math.floor(data.current.temp_c).toString(),
            condition: mappedCondition,
            isNight,
          };

          return acc;
        }, {} as { [city: string]: { temp: string; condition: "sunny" | "cloudy" | "rainy" | "snowy"; isNight: boolean } });

        setWeatherData(results);
      } catch (error) {
        console.error("❌ Error in fetchWeather:", error);
        console.timeEnd("⏳ Fetching Weather Data"); // ✅ Ensure the timer always ends
      }
    };

    fetchWeather();
  }, []);

  return (
    <PageWrapper>
      <O_WeatherCardsGrid
        cards={about.languages}
        currentLanguage={currentLanguage}
        weatherData={weatherData}
      />
    </PageWrapper>
  );
};

export default TestingShit;
