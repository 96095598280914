import React, { useEffect, useState, useMemo } from "react";
import { about, weatherCategories } from "../db";
import M_PageHeader from "../components/Molecules/M_PageHeader";
import M_AboutCardsGrid from "../components/Molecules/M_AboutCardsGrid";
import styled from "styled-components";
import M_Accordeon from "../components/Molecules/M_Accordeon";
import A_PageTextDivider from "../components/Atoms/A_PageTextDivider";
import { useNavigate } from "react-router-dom";
import T_ProjectsGrid from "../components/Templates/T_ProjectsGrid";
import { Project } from "../db/types";
import M_PseudoAccordeon from "../components/Molecules/M_PseudoAccordeon";
import M_MobPseudoAccordeon from "../components/Molecules/M_MobPseudoAccordeon";
import { useScreenSize } from "../styles/ScreenSizeContext";
import { media } from "../styles/mediaQueries";
import { Helmet } from "react-helmet-async";
import O_WeatherCardsGrid from "../components/Organisms/O_WeatherCardsGrid";

const API_KEY = process.env.REACT_APP_WEATHER_API_KEY;

interface AboutPageProps {
  currentLanguage: "en" | "ru";
  t: (key: string) => string;
  projects: Array<Project>;
}

const AboutPageContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding-left: calc(16px + env(safe-area-inset-left, 0px));
  padding-right: calc(16px + env(safe-area-inset-right, 0px));
  position: relative;
  gap: 16px;

  ${media.laptop} {
    padding: 0px var(--side-padding);
    gap: 120px;
  }
`;

const AboutPageInnerContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 160px;
`;

const AboutPagePart = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const AboutPage: React.FC<AboutPageProps> = ({
  currentLanguage,
  t,
  projects,
}) => {
  const navigate = useNavigate();
  const { isTabletLandscape, isLaptop, isTablet } = useScreenSize();
  const selectedProjects = projects.filter((_, index) =>
    [0, 2, 4].includes(index)
  );

  const [weatherData, setWeatherData] = useState<{
    [city: string]: {
      temp: string;
      condition: "sunny" | "cloudy" | "rainy" | "snowy";
      isNight: boolean;
    };
  }>({});

  const cityNames = useMemo(
    () => about.languages.map((lang) => lang.cityName.en),
    []
  );

  useEffect(() => {
    const fetchWeather = async () => {
      try {
        const responses = await Promise.all(
          cityNames.map((city) =>
            fetch(
              `https://api.weatherapi.com/v1/current.json?key=${API_KEY}&q=${city}`
            )
              .then((res) => res.json())
              .catch((error) => {
                console.error(`❌ Error fetching weather for ${city}:`, error);
                return null;
              })
          )
        );

        const results = responses.reduce((acc, data, index) => {
          if (!data) return acc;

          const city = cityNames[index];
          const weatherCode = data.current.condition.code;
          const mappedCondition = weatherCategories[weatherCode] ?? "cloudy";
          const isNight = data.current.is_day === 0;

          acc[city] = {
            temp: Math.floor(data.current.temp_c).toString(),
            condition: mappedCondition,
            isNight,
          };

          return acc;
        }, {} as { [city: string]: { temp: string; condition: "sunny" | "cloudy" | "rainy" | "snowy"; isNight: boolean } });

        setWeatherData(results);
      } catch (error) {
        console.error("❌ Error in fetchWeather:", error);
      }
    };

    fetchWeather();
  }, []);

  return (
    <>
      <Helmet>
        <title>Polina Sotnikova • About</title>
      </Helmet>
      <M_PageHeader
        multiLine
        jobless={t("about.looking")}
        bigText={t("about.moving")}
        iconName="sparkles"
        bigTextB={t("about.since")}
        bigTextSpan={t("about.figma")}
        currentLanguage={currentLanguage}
        columnAHeader={t("about.currently")}
        columnBHeader={t("about.based")}
        columnCHeader={t("about.specializations")}
        columnAText={about.currently[currentLanguage]}
        columnBText={about.based[currentLanguage]}
        columnCText={about.who[currentLanguage]}
      />
      <AboutPageContentWrapper>
        <AboutPageInnerContentWrapper>
          <M_AboutCardsGrid
            currentLanguage={currentLanguage}
            cards={about.aboutMeCards}
          />
          <M_PageHeader
            wrapped
            currentLanguage={currentLanguage}
            columnAHeader={t("about.contactMe")}
            columnBHeader={t("about.followMe")}
            contactMeLinks={about.contactMeLinks}
            followMeLinks={about.followMeLinks}
            s_description={about.aboutMeText.header[currentLanguage]}
            description={about.aboutMeText.text[currentLanguage]}
            ps={about.aboutMeText.ps[currentLanguage]}
            links={about.bigLinks}
            slash
          />
          <AboutPagePart>
            <A_PageTextDivider header={t("about.workExperience")} />
            <M_Accordeon
              items={about.workExperience}
              currentLanguage={currentLanguage}
              t={t}
            />
          </AboutPagePart>
          <AboutPagePart>
            <A_PageTextDivider header={t("about.education")} />
            {isTabletLandscape || isLaptop ? (
              <M_PseudoAccordeon
                items={about.education}
                currentLanguage={currentLanguage}
                t={t}
              />
            ) : (
              <M_MobPseudoAccordeon
                items={about.education}
                currentLanguage={currentLanguage}
                t={t}
              />
            )}
          </AboutPagePart>
          <AboutPagePart>
            <A_PageTextDivider header={t("about.skillsTech")} />
            <M_Accordeon
              items={about.skills}
              currentLanguage={currentLanguage}
              t={t}
            />
          </AboutPagePart>
          <AboutPagePart>
            <A_PageTextDivider header={t("about.languages")} />
            <O_WeatherCardsGrid
              cards={about.languages}
              currentLanguage={currentLanguage}
              weatherData={weatherData}
            />
          </AboutPagePart>
          <AboutPagePart>
            <A_PageTextDivider header={t("about.achievementsHeader")} />
            <M_AboutCardsGrid
              currentLanguage={currentLanguage}
              cards={about.achievements}
            />
          </AboutPagePart>
          <AboutPagePart style={{ marginTop: "36px" }}>
            <A_PageTextDivider
              header={t("about.selected")}
              text={t("about.loveit")}
              iconName="similar"
              reverse
              buttonText={t("about.seeAll")}
              handleButtonClick={() => navigate("/work")}
            />
            <T_ProjectsGrid
              projects={selectedProjects}
              featured
              currentLanguage={currentLanguage}
              vertical={isTabletLandscape || isTablet || isLaptop}
            />
          </AboutPagePart>
        </AboutPageInnerContentWrapper>
      </AboutPageContentWrapper>
    </>
  );
};

export default AboutPage;
