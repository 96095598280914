// src/components/Atoms/A_CaseImage/tsx
import React from "react";
import { FlexBox, PP_18, PP_20 } from "../Quarks";
import styled, { useTheme } from "styled-components";
import Image from "../Quarks/Image";
import Video from "../Quarks/Video";
import LazyLoad from "react-lazyload";
import { useScreenSize } from "../../styles/ScreenSizeContext";

interface CaseImageProps {
  src: string;
  projectName: string;
  imageDescription?: string;
  id?: any;
  onClick?: any;
  ui?: boolean;
  shouldAutoplay?: boolean;
  poster?: string;
}

const CaseImageWrapper = styled(FlexBox)<{ $body?: boolean }>`
  flex: 1;
  padding: var(--padding-8-20);
  border-radius: var(--card-border-radius);
  background-color: ${({ theme }) => theme.cards.bg};
  flex-direction: column;
  align-items: flex-start;
  box-shadow: ${({ theme }) => theme.cards.infoCardShadow};
  gap: var(--gap-16);
  width: 100%;
  box-sizing: border-box;
  img,
  video,
  .sk {
    border-radius: var(--incard-img-border-radius);
  }
`;

const isVideo = (src: string): boolean => {
  return src.includes(".webm") || src.includes(".mp4");
};

const A_CaseImage = (props: CaseImageProps) => {
  const theme = useTheme();
  const { isLaptop } = useScreenSize();

  if (props.imageDescription) {
    return (
      <>
        {isVideo(props.src) ? (
          <CaseImageWrapper>
            <LazyLoad
              offset={200}
              once
              style={{ width: "100%", aspectRatio: "1920/1045" }}
            >
              <Video
                id={props.id}
                shouldAutoplay={props.shouldAutoplay}
                poster={props.poster}
                src={props.src}
                $width="100%"
                $aspectRatio={16 / 9}
              />
            </LazyLoad>
          </CaseImageWrapper>
        ) : (
          <CaseImageWrapper onClick={props.onClick}>
            <LazyLoad
              offset={200}
              once
              style={{ width: "100%", aspectRatio: "1920/1045" }}
            >
              <Image
                src={props.src}
                alt={`${props.projectName} case image`}
                id={props.id}
                $width="100%"
                $aspectRatio={16 / 9}
                $imageDescription={props.imageDescription}
              />
            </LazyLoad>
            {isLaptop ? (
              <PP_20 color={theme.medium_grey}>{props.imageDescription}</PP_20>
            ) : (
              <PP_18 medium color={theme.medium_grey}>
                {props.imageDescription}
              </PP_18>
            )}
          </CaseImageWrapper>
        )}
      </>
    );
  } else if (props.ui) {
    return (
      <LazyLoad offset={200} once style={{ width: "20vw" }}>
        <Video ui src={props.src} $width="20vw" $aspectRatio={2.3} />
      </LazyLoad>
    );
  } else {
    return (
      <>
        {isVideo(props.src) ? (
          <LazyLoad
            offset={200}
            once
            style={{ width: "100%", aspectRatio: "1920/1045" }}
          >
            <Video
              shouldAutoplay={props.shouldAutoplay}
              poster={props.poster}
              src={props.src}
              $width="100%"
              $aspectRatio={16 / 9}
              id={props.id}
            />
          </LazyLoad>
        ) : (
          <LazyLoad
            offset={200}
            once
            style={{ width: "100%", aspectRatio: "1920/1045" }}
          >
            <Image
              src={props.src}
              alt={`${props.projectName} case image`}
              id={props.id}
              $width="100%"
              $aspectRatio={16 / 9}
              onClick={props.onClick}
            />
          </LazyLoad>
        )}
      </>
    );
  }
};

export default A_CaseImage;
