import React, { useEffect, useRef, useState } from "react";
import styled, { keyframes } from "styled-components";

const pixelSize = 32;

const weatherColors = {
  sunny: ["#4A90E2", "#6FAFDC", "#98CFF5"],
  rainy: ["#364F6B", "#4A6C89", "#6E8CA6"],
  snowy: ["#D6D6D6", "#E4E4E4", "#F2F2F2"],
  cloudy: ["#6D7A86", "#87939F", "#A1ABB5"],
  nightClear: ["#1B1B2F", "#2E2E4A", "#464665"],
  nightCloudy: ["#2A2E37", "#3D4451", "#515867"],
} as const;

const baseAnimation = keyframes`
    0% { filter: brightness(90%) contrast(100%); }
    50% { filter: brightness(100%) contrast(140%); }
    100% { filter: brightness(90%) contrast(100%); }
`;

const StyledCanvas = styled.canvas<{ $condition: keyof typeof weatherColors }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  animation: ${baseAnimation} 5s infinite alternate ease-in-out;
`;

interface WeatherProps {
  condition: keyof typeof weatherColors;
}

const A_WeatherPixelatedGradient: React.FC<WeatherProps> = ({ condition }) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [size, setSize] = useState({ width: 287, height: 287 });

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        const newWidth = Math.round(entry.contentRect.width);
        const newHeight = Math.round(entry.contentRect.height);

        if (newWidth !== size.width || newHeight !== size.height) {
          setSize({ width: newWidth, height: newHeight });
        }
      }
    });

    if (canvasRef.current?.parentElement) {
      resizeObserver.observe(canvasRef.current.parentElement);
    }

    return () => resizeObserver.disconnect();
  }, [size]);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;
    const ctx = canvas.getContext("2d", { willReadFrequently: true });
    if (!ctx) return;

    canvas.width = size.width;
    canvas.height = size.height;

    const { width, height } = canvas;
    const cols = Math.ceil(width / pixelSize);
    const rows = Math.ceil(height / pixelSize);
    let centerX = width / 2;
    let centerY = height / 2;

    if (condition === "rainy") {
      centerX = width * 0.8;
      centerY = height * 0.2;
    } else if (condition === "cloudy" || condition === "nightCloudy") {
      centerX = width * 0.5;
      centerY = height * 0.1;
    }

    const maxRadius = Math.sqrt(width ** 2 + height ** 2);
    const gradient = ctx.createRadialGradient(
      centerX,
      centerY,
      0,
      centerX,
      centerY,
      maxRadius
    );
    const gradientValues = weatherColors[condition];

    gradientValues.forEach((color, index) => {
      const stopPosition = index / (gradientValues.length - 1);
      gradient.addColorStop(stopPosition, color);
    });

    ctx.fillStyle = gradient;
    ctx.fillRect(0, 0, width, height);
    const imageData = ctx.getImageData(0, 0, width, height).data;

    ctx.clearRect(0, 0, width, height);

    requestAnimationFrame(() => {
      for (let x = 0; x < cols; x++) {
        for (let y = 0; y < rows; y++) {
          const sampleX = Math.min(x * pixelSize + pixelSize / 2, width - 1);
          const sampleY = Math.min(y * pixelSize + pixelSize / 2, height - 1);
          const index = (Math.floor(sampleY) * width + Math.floor(sampleX)) * 4;

          const color = `rgb(${imageData[index]}, ${imageData[index + 1]}, ${
            imageData[index + 2]
          })`;
          ctx.fillStyle = color;
          ctx.fillRect(x * pixelSize, y * pixelSize, pixelSize, pixelSize);
        }
      }
    });
  }, [condition, size]);

  return <StyledCanvas ref={canvasRef} $condition={condition} />;
};

export default A_WeatherPixelatedGradient;
