// M_WeatherCard
import React from "react";
import A_WeatherPixelatedGradient from "../Atoms/A_WeatherPixelatedGradient";
import { FlexBox, PP_24, PP_80, PP_20, PP_16, PP_48 } from "../Quarks";
import styled from "styled-components";
import { media } from "../../styles/mediaQueries";
import { useScreenSize } from "../../styles/ScreenSizeContext";

interface WeatherCardProps {
  condition:
    | "sunny"
    | "cloudy"
    | "rainy"
    | "snowy"
    | "nightClear"
    | "nightCloudy";
  language: string;
  level: string;
  city: string;
  degree: string;
  time: string;
  rotation: number;
  timeZone: string;
}

const soundMap: { [key: string]: string[] } = {
  English: ["/assets/sounds/eng1.mp3", "/assets/sounds/eng2.mp3"],
  Русский: ["/assets/sounds/ru1.mp3", "/assets/sounds/ru2.mp3"],
  Deutsch: ["/assets/sounds/de1.mp3", "/assets/sounds/de2.mp3"],
  한국어: ["/assets/sounds/ko1.mp3", "/assets/sounds/ko2.mp3"],
};

const WeatherCardWrapper = styled(FlexBox)<{ $rotation: number }>`
  width: 100%;
  position: relative;
  overflow: hidden;
  padding: var(--h-card-padding);
  box-sizing: border-box;
  border-radius: var(--card-border-radius);
  transform: rotate(${({ $rotation }) => $rotation}deg);
  transition: all 0.4s ease-out;

  &:active {
    filter: blur(10px);
  }

  ${media.tabletsL} {
    width: 287px;
    height: 287px;
    cursor: pointer;

    &:hover {
      transform: scale(1.02);
    }
  }

  ${media.laptop} {
    width: 287px;
    height: 287px;
    cursor: pointer;

    &:hover {
      transform: scale(1.02);
    }
  }
`;

const WeatherCardInfoWrapper = styled(FlexBox)`
  z-index: 10;
  height: 100%;
  justify-content: space-between;
  width: 100%;

  ${media.tabletsL} {
    flex-direction: column;
  }

  ${media.laptop} {
    flex-direction: column;
  }
`;

const M_WeatherCard: React.FC<WeatherCardProps> = ({
  condition,
  language,
  level,
  city,
  degree = "N/A",
  time,
  rotation,
  timeZone = "GMT",
}) => {
  const { isTabletLandscape, isLaptop } = useScreenSize();

  const playSound = () => {
    const sounds = soundMap[language];
    if (!sounds) return;

    const randomSound = sounds[Math.floor(Math.random() * sounds.length)];
    const audio = new Audio(randomSound);
    audio.play();
  };

  return (
    <WeatherCardWrapper $rotation={rotation} onClick={playSound}>
      <A_WeatherPixelatedGradient condition={condition} />
      <WeatherCardInfoWrapper>
        {isLaptop || isTabletLandscape ? (
          <>
            <div>
              <PP_24 medium offsetLeft={4} color="#FBF8F3">
                {language}
              </PP_24>
              <PP_80 medium lineHeight="80px" color="#FBF8F3">
                {level}
              </PP_80>
            </div>
            <div>
              <PP_20 color="#FBF8F3" offsetLeft={4}>
                {city}
              </PP_20>
              <FlexBox $gap="6px" $offsetLeft="4px">
                <PP_20 color="#FBF8F3">{degree}°C</PP_20>
                <PP_20 color="#FBF8F3">·</PP_20>
                <PP_20 color="#FBF8F3">
                  {timeZone} {time}
                </PP_20>
              </FlexBox>
            </div>
          </>
        ) : (
          <>
            <FlexBox $direction="column" $gap="32px">
              <div>
                <PP_20 medium offsetLeft={4} color="#FBF8F3">
                  {language}
                </PP_20>
                <PP_16 color="#FBF8F3" offsetLeft={4}>
                  {city}
                </PP_16>
              </div>
              <FlexBox $gap="6px" $offsetLeft="4px">
                <PP_16 color="#FBF8F3">{degree}°C</PP_16>
                <PP_16 color="#FBF8F3">·</PP_16>
                <PP_16 color="#FBF8F3">
                  {timeZone} {time}
                </PP_16>
              </FlexBox>
            </FlexBox>
            <PP_48 medium lineHeight="48px" color="#FBF8F3">
              {level}
            </PP_48>
          </>
        )}
        {/* <FlexBox $direction="column" $gap="32px">
          <div>
            <PP_20 medium offsetLeft={4} color="#FBF8F3">
              {language}
            </PP_20>
            <PP_16 color="#FBF8F3" offsetLeft={4}>
              {city}
            </PP_16>
          </div>
          <FlexBox $gap="6px" $offsetLeft="4px">
            <PP_16 color="#FBF8F3">{degree}°C</PP_16>
            <PP_16 color="#FBF8F3">·</PP_16>
            <PP_16 color="#FBF8F3">
              {timeZone} {time}
            </PP_16>
          </FlexBox>
        </FlexBox>
        <PP_48 medium lineHeight="48px" color="#FBF8F3">
          {level}
        </PP_48> */}

        {/* <div>
          <PP_24 medium offsetLeft={4} color="#FBF8F3">
            {language}
          </PP_24>
          <PP_80 medium lineHeight="80px" color="#FBF8F3">
            {level}
          </PP_80>
        </div>
        <div>
          <PP_20 color="#FBF8F3" offsetLeft={4}>
            {city}
          </PP_20>
          <FlexBox $gap="6px" $offsetLeft="4px">
            <PP_20 color="#FBF8F3">{degree}°C</PP_20>
            <PP_20 color="#FBF8F3">·</PP_20>
            <PP_20 color="#FBF8F3">
              {timeZone} {time}
            </PP_20>
          </FlexBox>
        </div> */}
      </WeatherCardInfoWrapper>
    </WeatherCardWrapper>
  );
};

export default M_WeatherCard;
