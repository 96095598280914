// O_WeatherCardsGrid.tsx
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { LanguageItem } from "../../db/types";
import { FlexBox } from "../Quarks";
import M_WeatherCard from "../Molecules/M_WeatherCard";

interface WeatherCardsGridProps {
  cards: LanguageItem[];
  currentLanguage: "en" | "ru";
  weatherData: {
    [city: string]: {
      temp: string;
      condition: string;
      isNight: boolean;
    };
  };
}

const WeatherCardsGridWrapper = styled(FlexBox)`
  gap: var(--gap-8-104);
  justify-content: center;
`;

const getNightCondition = (condition: "sunny" | "cloudy" | "rainy" | "snowy") =>
  condition === "sunny" ? "nightClear" : "nightCloudy";

const O_WeatherCardsGrid = ({
  currentLanguage,
  cards,
  weatherData,
}: WeatherCardsGridProps) => {
  const [cityTimes, setCityTimes] = useState<{ [city: string]: string }>({});

  const updateTimes = () => {
    setCityTimes(() =>
      cards.reduce((acc, card) => {
        acc[card.cityName.en] = new Intl.DateTimeFormat("en-US", {
          timeZone: card.IANA,
          hour: "2-digit",
          minute: "2-digit",
          hour12: false,
        }).format(new Date());
        return acc;
      }, {} as { [city: string]: string })
    );
  };

  useEffect(() => {
    updateTimes();
    const interval = setInterval(updateTimes, 60000);
    return () => clearInterval(interval);
  }, [cards]);

  return (
    <WeatherCardsGridWrapper>
      {cards.map((card, index) => {
        const cityName = card.cityName.en;
        const weather = weatherData[cityName];
        return (
          <M_WeatherCard
            key={index}
            condition={
              weather?.isNight
                ? getNightCondition(
                    weather?.condition as "sunny" | "cloudy" | "rainy" | "snowy"
                  )
                : (weather?.condition as
                    | "sunny"
                    | "cloudy"
                    | "rainy"
                    | "snowy") || "cloudy"
            }
            language={card.language}
            level={card.level}
            city={card.cityName[currentLanguage]}
            degree={weather?.temp || "N/A"}
            time={cityTimes[cityName]}
            rotation={index % 2 === 0 ? 0.5 : -0.5}
            timeZone={card.timeZone}
          />
        );
      })}
    </WeatherCardsGridWrapper>
  );
};

export default O_WeatherCardsGrid;
