import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { DJR_128, DJR_64 } from "../Quarks";
import { useScreenSize } from "../../styles/ScreenSizeContext";

const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*";

const emojiSet = new Set(["🤐", "🤫"]);

const FlipContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 280px;
  grid-column: span 2;
`;

const AnimatedText = styled.span<{ $isGrey?: boolean }>`
  display: inline-block;
  font-family: monospace;
  font-weight: bold;
  min-width: 1ch;
  color: ${({ $isGrey, theme }) => ($isGrey ? theme.navigation.lightest_grey : theme.navigation.white)};
  transition: transform 0.2s ease-in-out;
`;

interface A_FlapTextProps {
  isOpen: boolean;
}

const A_FlapText: React.FC<A_FlapTextProps> = ({ isOpen }) => {
  const { isTablet, isTabletLandscape, isLaptop } = useScreenSize();

  const finalText =
    isTablet || isTabletLandscape || isLaptop
      ? ["@", "#", "$", "🤐", "N", "D", "A", "🤫", "%", "^", "&"]
      : ["🤐", "N", "D", "A", "🤫"];

  const [displayText, setDisplayText] = useState(finalText.map(() => " "));

  useEffect(() => {
    if (!isOpen) return;

    setDisplayText(finalText.map(() => " "));

    finalText.forEach((char, index) => {
      if (emojiSet.has(char)) {
        setTimeout(() => {
          setDisplayText((prev) => {
            const newText = [...prev];
            newText[index] = char;
            return newText;
          });
        }, index * 150);
      } else {
        setTimeout(() => {
          let iterations = 0;
          const interval = setInterval(() => {
            setDisplayText((prev) => {
              const newText = [...prev];
              newText[index] =
                characters[Math.floor(Math.random() * characters.length)];
              return newText;
            });

            iterations++;
            if (iterations > 5) {
              clearInterval(interval);
              setDisplayText((prev) => {
                const newText = [...prev];
                newText[index] = finalText[index];
                return newText;
              });
            }
          }, 100);
        }, index * 150);
      }
    });
  }, [isOpen]);

  return (
    <FlipContainer>
      {isTablet || isTabletLandscape || isLaptop ? (
        <DJR_128>
          {displayText.map((char, index) => (
            <AnimatedText key={index} $isGrey={index < 3 || index > 7}>
              {char}
            </AnimatedText>
          ))}
        </DJR_128>
      ) : (
        <DJR_64>
          {displayText.map((char, index) => (
            <AnimatedText key={index}>{char}</AnimatedText>
          ))}
        </DJR_64>
      )}
    </FlipContainer>
  );
};

export default A_FlapText;
