// A_ThemeSwitcher.tsx
import React, { useState, useRef } from "react";
import styled, { keyframes, css } from "styled-components";
import { media } from "../../styles/mediaQueries";
import { FlexBox } from "../Quarks";
import { PP_20 } from "../Quarks";

interface ThemeSwitcherProps {
  theme: string;
  toggleTheme: (theme: "light" | "dark" | "system") => void;
  mobColor?: boolean;
}

const fullRotation = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
`;

const EmojiWrapper = styled.p`
  position: relative;
  font-size: 0.875rem;
  line-height: 0.875rem;
  width: 0.875rem;
  height: 0.875rem;
  display: flex;
  align-items: center;
  justify-content: center;

  @media only screen and (max-width: 1366px) and (pointer: fine) and (hover: hover) {
    padding-top: 2px;
  }

  ${media.laptop} {
    padding-top: 2px;
  }
`;

const ThemeSwitcherButton = styled.button<{
  $rotating: boolean;
  $mobColor?: boolean;
}>`
  width: 44px;
  height: 44px;
  border: 1px solid hsla(0, 0%, 100%, 0.025);
  background-color: ${({ theme }) => theme.navigation.navbuttonswrapper};
  box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.1);
  border-radius: var(--button-border-radius);
  -webkit-backdrop-filter: blur(12px) saturate(140%);
  backdrop-filter: blur(12px) saturate(140%);
  cursor: pointer;
  font-size: 0.875rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.4s ease;
  align-self: center;
  -webkit-transform-origin: center center;

  ${({ $rotating }) =>
    $rotating &&
    css`
      animation: ${fullRotation} 0.8s forwards;
    `}

  ${media.tablets} {
    width: 2rem;
    height: 2rem;
  }

  @media only screen and (max-width: 1366px) and (pointer: fine) and (hover: hover) {
    width: 1.5rem;
    height: 1.5rem;

    &:hover {
      transform: rotate(45deg);
    }
  }

  ${media.laptop} {
    width: 1.5rem;
    height: 1.5rem;

    &:hover {
      transform: rotate(45deg);
    }
  }
`;

const ContextMenu = styled.div<{ $opened: boolean; $block: boolean }>`
  position: absolute;
  top: calc(100% + 12px);
  left: 50%;
  transform: translateX(-50%);
  opacity: ${({ $opened }) => ($opened ? 1 : 0)};
  overflow: hidden;
  display: ${({ $block }) => ($block ? "block" : "none")};
  padding: ${({ $opened }) => ($opened ? "8px" : 0)};
  border-radius: var(--context-menu-border-radius);
  background-color: ${({ theme }) => theme.navigation.navbuttonswrapper};
  box-shadow: ${({ theme }) => theme.navigation.shadow};
  border: 1px solid hsla(0, 0%, 100%, 0.025);
  -webkit-backdrop-filter: blur(12px) saturate(140%);
  backdrop-filter: blur(12px) saturate(140%);
  transition: opacity 0.3s ease, padding 0.6s ease;
`;

const ThemeSwitcherWrapper = styled(FlexBox)`
  position: relative;
`;

const ThemeModeButton = styled(FlexBox)`
  width: 100%;
  position: relative;
  padding: 10px 20px 10px 16px;
  align-items: center;
  border-radius: var(--context-menu-inner-border-radius);
  flex-wrap: nowrap;
  cursor: pointer;
  transition: all 0.3s ease;
  gap: 24px;
`;

const HoverBackground = styled.div<{
  $height: number;
  $top: number;
  $hasTransition: boolean;
  $visible: boolean;
}>`
  height: ${({ $height }) => $height}px;
  position: absolute;
  border-radius: var(--button-border-radius);
  background-color: ${({ theme }) => theme.navigation.cmHoverbg};
  top: ${({ $top }) => $top}px;
  left: 8px;
  right: 8px;
  opacity: ${({ $visible }) => ($visible ? 1 : 0)};
  transition: ${({ $hasTransition }) =>
    $hasTransition
      ? "top 0.3s ease, width 0.3s ease, opacity 0.5s ease"
      : "opacity 0.5s ease"};
  z-index: 0;
`;

const ButtonDot = styled.div`
  background-color: ${({ theme }) => theme.medium_grey};
  width: 4px;
  height: 4px;
  border-radius: var(--button-border-radius);
  transition: all 0.4s ease;
  justify-content: space-between;
  flex-shrink: 0;
`;

const A_ThemeSwitcher: React.FC<ThemeSwitcherProps> = ({
  toggleTheme,
  mobColor,
}) => {
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
  const [isSwitchingButtons, setIsSwitchingButtons] = useState(false);
  const [isCMHovered, setIsCMHovered] = useState(false);
  const [isCMBlock, setIsCMBlock] = useState(false);
  const [isCMOpened, setIsCMOpened] = useState(false);
  const buttonRefs = useRef<(HTMLDivElement | null)[]>([]);
  const [rotating, setRotating] = useState(false);
  const buttons = [
    { key: "system", icon: "🌗" },
    { key: "light", icon: "☀️" },
    { key: "dark", icon: "🌙" },
  ];

  const handleOpenCM = () => {
    if (!isCMBlock) {
      setIsCMBlock(true);
      setTimeout(() => {
        setIsCMOpened(true);
      }, 50);
    } else {
      setIsCMOpened(false);
      setTimeout(() => {
        setIsCMBlock(false);
      }, 600);
    }
  };

  const handleThemeClick = (themeKey: "light" | "dark" | "system") => {
    toggleTheme(themeKey);
    setRotating(true);
    setTimeout(() => {
      setRotating(false);
    }, 800);
  };

  const handleMouseEnter = (index: number) => {
    if (hoveredIndex === null) {
      setIsSwitchingButtons(false);
    } else {
      setIsSwitchingButtons(true);
    }
    setHoveredIndex(index);
  };

  const savedTheme = localStorage.getItem("theme");
  const systemPrefersDark = window.matchMedia(
    "(prefers-color-scheme: dark)"
  ).matches;
  const computedTheme = savedTheme
    ? savedTheme
    : systemPrefersDark
    ? "dark"
    : "light";
  const isSystemMode = !savedTheme;

  return (
    <ThemeSwitcherWrapper>
      <ThemeSwitcherButton
        $rotating={rotating}
        $mobColor={mobColor}
        onClick={handleOpenCM}
      >
        <EmojiWrapper>
          {isSystemMode ? "🌗" : computedTheme === "light" ? "☀️" : "🌙"}
        </EmojiWrapper>
      </ThemeSwitcherButton>
      <ContextMenu
        $opened={isCMOpened}
        $block={isCMBlock}
        onMouseEnter={() => setIsCMHovered(true)}
        onMouseLeave={() => setIsCMHovered(false)}
      >
        <FlexBox style={{ overflow: "hidden" }}>
          <HoverBackground
            $height={
              hoveredIndex !== null && buttonRefs.current[hoveredIndex]
                ? buttonRefs.current[hoveredIndex].offsetHeight
                : 0
            }
            $top={
              hoveredIndex !== null && buttonRefs.current[hoveredIndex]
                ? buttonRefs.current[hoveredIndex].offsetTop
                : 0
            }
            $hasTransition={isSwitchingButtons}
            $visible={isCMHovered}
          />
          {buttons.map((button, index) => (
            <ThemeModeButton
              key={button.key}
              ref={(el) => (buttonRefs.current[index] = el)}
              onClick={() =>
                handleThemeClick(button.key as "light" | "dark" | "system")
              }
              onMouseEnter={() => handleMouseEnter(index)}
            >
              <FlexBox
                $gap="12px"
                style={{ flexWrap: "nowrap", width: "100%" }}
              >
                <PP_20 uppercase medium>
                  {button.icon}
                </PP_20>
                <PP_20 uppercase medium>
                  {button.key}
                </PP_20>
              </FlexBox>
              <ButtonDot
                style={{
                  opacity: isSystemMode
                    ? button.key === "system"
                      ? 1
                      : 0
                    : computedTheme === button.key
                    ? 1
                    : 0,
                }}
              />
            </ThemeModeButton>
          ))}
        </FlexBox>
      </ContextMenu>
    </ThemeSwitcherWrapper>
  );
};

export default A_ThemeSwitcher;
