// A_MobThemeSwitcher.tsx
import React, { useState } from "react";
import styled from "styled-components";
import { media } from "../../styles/mediaQueries";
import { FlexBox } from "../Quarks";

interface ThemeSwitcherProps {
  theme: string;
  toggleTheme: (theme: "light" | "dark" | "system") => void;
  mobColor?: boolean;
}

const ThemeSwitcherWrapper = styled(FlexBox)`
  position: relative;
  background-color: ${({ theme }) => theme.navigation.themeswitcher};
  border-radius: var(--button-border-radius);
  -webkit-backdrop-filter: blur(12px) saturate(140%);
  backdrop-filter: blur(12px) saturate(140%);
`;

const ThemeSwitcherButton = styled.button<{
  $mobColor?: boolean;
  $selected: boolean;
  $opened: boolean;
}>`
  width: 44px;
  height: 44px;
  border: none;
  background: ${({ theme, $selected, $opened }) =>
    $selected && $opened ? theme.navigation.themeSwitcherMob : "transparent"};
  border-radius: var(--button-border-radius);
  cursor: pointer;
  font-size: 0.875rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background 0.4s ease, width 0.6s ease;
  align-self: center;
  -webkit-transform-origin: center center;
  overflow: hidden;
  padding: 0;

  ${media.tablets} {
    width: 2rem;
    height: 2rem;
  }

  @media only screen and (max-width: 1366px) and (pointer: fine) and (hover: hover) {
    width: 1.5rem;
    height: 1.5rem;
  }

  ${media.laptop} {
    width: 1.5rem;
    height: 1.5rem;
  }
`;

const EmojiWrapper = styled.p`
  position: relative;
  font-size: 0.875rem;
  line-height: 0.875rem;
  width: 0.875rem;
  height: 0.875rem;
  display: flex;
  align-items: center;
  justify-content: center;

  @media only screen and (max-width: 1366px) and (pointer: fine) and (hover: hover) {
    padding-top: 2px;
  }

  ${media.laptop} {
    padding-top: 2px;
  }
`;

const A_MobThemeSwitcher: React.FC<ThemeSwitcherProps> = ({
  toggleTheme,
  mobColor,
}) => {
  const [isOpened, setIsOpened] = useState(false);
  const buttons = [
    { key: "light", icon: "☀️" },
    { key: "dark", icon: "🌙" },
  ];

  const handleThemeClick = (themeKey: "light" | "dark" | "system") => {
    toggleTheme(themeKey);
    setIsOpened(false);
  };

  const handleOpen = () => {
    console.log(isOpened);
    if (!isOpened) {
      setIsOpened(true);
    }
  };

  const savedTheme = localStorage.getItem("theme");

  return (
    <ThemeSwitcherWrapper className="themeswitcherwrapper" onClick={handleOpen}>
      {buttons.map((button, index) => (
        <ThemeSwitcherButton
          key={index}
          $mobColor={mobColor}
          $selected={savedTheme === button.key}
          $opened={isOpened}
          style={{
            pointerEvents: !isOpened ? "none" : "auto",
            width:
              savedTheme === button.key ? "44px" : isOpened ? "44px" : "0px",
          }}
          onClick={() =>
            handleThemeClick(button.key as "light" | "dark" | "system")
          }
        >
          <EmojiWrapper>{button.icon}</EmojiWrapper>
        </ThemeSwitcherButton>
      ))}
    </ThemeSwitcherWrapper>
  );
};

export default A_MobThemeSwitcher;
